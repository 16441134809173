<template>
	<div
		v-if="markup.length"
		ref="content"
		:class="[
			'html-content',
			lineClamp
		]"
		v-html="markup"
	/>
</template>

<script async>
export default {
	props: {
		markup: {
			type: String,
			default: ''
		},
		expanded: {
			type: Boolean,
			default: false
		},
		useLineClamp: {
			type: Boolean,
			default: false
		},
		useLineClampFour: {
			type: Boolean,
			default: false
		}
	},
	emits: [ 'hide-trigger' ],
	computed: {
		lineClamp() {
			if (!this.expanded && this.useLineClampFour) return 'line-clamp-4'
			if (!this.expanded && this.useLineClamp) return 'line-clamp-1'
			return ''
		},
		contentClientHeight() {
			return this.$refs.content.clientHeight
		},
		contentScrollHeight() {
			return this.$refs.content.scrollHeight
		},
		lineClampClass() {
			return `line-clamp-${this.lineClampNumber}`
		}
	},
	mounted() {
		if (this.contentClientHeight >= this.contentScrollHeight) {
			this.$emit('hide-trigger')
		}
	}
}
</script>

<style scoped>
	.html-content > p {
		/* .leading-7 */
		line-height: 1.75;
	}
	.html-content > p:last-of-type {
		margin-bottom: 0;
	}
</style>
